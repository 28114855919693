*:focus {
    outline: none !important;
}
.border-container {
    border-left: 1px solid #a5b1a591;
    border-right: 1px solid #a5b1a591;
    border-bottom: 1px solid #a5b1a591;
    padding: 10px;
}

#root {
    height: 100vh;
}

#page-wrap {
    text-align: center;
    overflow: auto;
}

.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

.bm-item:hover {
    color: white;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 80px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu {
    background: #508e4e;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #508e4e;
}

.bm-item-list {
    color: #b8b7ad;
}

.bm-overlay {
    background: none !important;
}

.label-danger {
    color: red !important;
}

.button-container {
    padding: 0px 15px;
    margin-top: 30px;
}

.btn-padding {
    margin: 5px !important;
}

.d-flex {
    display: flex;
}

.dropdown-menu {
    right: 0 !important;
    text-align: right !important;
}

.table td, .table th {
    padding: 0.65rem !important;
}
.margin-5 {
    margin: 5px;
}

.input-datepicker {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.react-datepicker-wrapper {
    display: inline !important;
}

.tr-danger {
    border: 3px solid red;
}

.form-control {
    box-shadow: none!important;
}

.componentContainer {
    margin-left: 5%;
    margin-right: 5%;
}

.label-title {
    font-weight: bolder;
}
.label-header {
    font-style: unset;
    font-size: 1.8em;
    font-weight: bold;
    color: #495057;
}

.section-preview {
    border: 1px solid #e0e0e0;
    padding: 15px;
}
.invalid-input {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + .75rem) !important;
    /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc354…%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;*/
    background-repeat: no-repeat !important;
    background-position: center right calc(.375em + .1875rem) !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}
.btn-fixed {
    width: 200px;
}
.selected-candidate {
    border: 3px solid red !important;
}
.text-uppercase {
    text-transform: uppercase;
}
table td {
    color: #4e5056 !important;
    font-weight: 400 !important;
}
.invalid-message {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
.container-check {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 4px;
    left: auto;
    margin-left: 5px;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*checkbox*/
.unassigned {
    color: dodgerblue;
}
.selected {
    color: darkgreen;
}
.no-selected {
    color: red;
}
.reservation {
    color: #282c34;
}
.checkbox-resend label {
    font-size: 11px !important;
    font-style: italic;
    color: crimson;
    margin-top: 3px !important;
}
.custom-navbar .dropleft .dropdown-toggle::before {
    display: contents !important;
}
.sidebar-list {}
.sidebar-list-item {
    list-style-type: none;
}
.bm-menu a {
    text-decoration: none !important;
}
.lbl-upload-result {
    font-family: sans-serif;
    font-weight: 100;
    color: red;
    font-size: 15px;
    font-style: inherit;
    font-kerning: auto;
}

.modal {
    z-index: 999999 !important;
}
.modal-content {
    margin-top: 150px;
}
