.App {
  /*text-align: center;*/
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-nav-bar-custom {
    background-color: #549E39;
}

.nb-text {
    color: white;
}
.form-container {
    margin-top: 5%;
}
.page-item.active .page-link {
    z-index: 0 !important;
    background-color: #28a745 !important;
    border-color: #14c820 !important;
    color: white !important;
}
.page-link {
    color: #28a745 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
