.sign-in-container {
    background: linear-gradient(to right,rgb(20, 191, 136) 0,rgba(0,175,102,1) 100%);
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
}
.sign-in-logo {
    text-align: center;
}
.sign-in-form-container {
    width: 35%;
    margin: 30px auto;
}
.sign-in-form {
    min-height: 550px;
    padding: 30px;
    background: #fff;
    text-align: left;
}
.MuiOutlinedInput-root.Mui-focused
.MuiOutlinedInput-notchedOutline {
    border-color: #00AAB4 !important;
}
.MuiFormLabel-root.Mui-focused {
    color: #00AAB4 !important;
}
.forgot-password-link {
    text-align: right;
    margin-bottom:0px;
}
.forgot-password-link a {
    color: #00aab4;
    text-decoration: underline;
    word-wrap: break-word;
    word-break: break-all;
}
.label-error {
    color: #f44336;
    margin: 8px 14px 0 !important;
    font-size: 0.75rem;
    min-height: 1em;
    text-align: left;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.03333em;
}
.error-link {
    color: #f44336 !important;
    text-decoration: underline !important;
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1200px) {
    .sign-in-form-container {
        width: 30%;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
    .sign-in-form-container {
        width: 40%;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {
    .sign-in-form-container {
        width: 90%;
    }
}

/*Media queries*/
@media (max-width: 480px) {
    .sign-in-form-container {
        width: 100%;
    }
}
